$primary: #212529;
$secondary: #212529;
$white: white;
$background: #c5cae9;

$custom-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "white": $white,
    "background": $background,
);

$navbar-height: 4rem;